<template>
  <div class="front-cont">
    <div class="row">
      <el-carousel
        ref="bannerRef"
        :autoplay="false"
        arrow="always"
        trigger="click"
        class="banner-img-cont"
      >
        <el-carousel-item v-for="item in Banners" :key="item.ImageKey">
          <img :src="item.Uri" class="banner-img" alt="" />
          <div class="f-home-content px-4 px-md-7 mt-2 mt-md-0" style="top: 0px">
            <div class="px-2 mt-2 mt-md-5">
              <div
                class="text-white text-shadow"
                v-html="item.BannerContent ? item.BannerContent : HomeData.content"
              ></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="f-bg-orange row justify-content-center py-3">
      <div class="col-md-6 d-flex">
        <el-input
          v-on:keyup.enter="searchNow"
          v-model="formData.Keyword"
          class="home-input"
          label="..."
        >
          <template #prepend>
            <i class="fas fa-search text-white" />
          </template>
        </el-input>

        <el-select
          v-model="formData.ServiceCatgCode"
          :placeholder="$t.translate('LBL_FILTER')"
          class="search-select"
          popper-class="front-popper"
        >
          <el-option
            v-for="item in OptionList"
            :key="'f-opt-' + item.value"
            :label="$t.translate(item.label)"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="row px-0 px-md-6 mb-5">
      <div class="col-md-6 mt-5 pr-md-5">
        <img
          :src="$axios.formulateCMSUrl(HomeData.SquareImageBanner)"
          style="width: 100%"
          alt=""
        />
      </div>

      <div
        v-for="(item, index) in Pagination.Items.filter(
          (it) => it?.DisplayDescInFrontend
        )"
        :key="item.ServiceKey"
        class="col-md-6 mt-4 mt-md-5 px-4 px-md-5 py-1 py-md-0"
        :class="{
          'f-bdf-orange': index % 3 == 0 && mq.current == 'xsmall',
          'f-bdf-blue': index % 3 == 1 && mq.current == 'xsmall',
          'f-bdf-green': index % 3 == 2 && mq.current == 'xsmall',
        }"
      >
        <el-divider
          style="height: 10px"
          class="d-none d-md-block mt-0 mb-4"
          :class="{
            'f-bg-orange': index % 3 == 0,
            'f-bg-blue': index % 3 == 1,
            'f-bg-green': index % 3 == 2,
          }"
        />

        <h2 class="d-block d-md-none mb-2">
          {{ $h.formatServiceName(item) }}
        </h2>

        <h3
          :class="{
            'f-text-orange': index % 3 == 0,
            'f-text-blue': index % 3 == 1,
            'f-text-green': index % 3 == 2,
          }"
        >
          {{ $t.translate(`FP_${item.ServiceCatgCode}`) }}
          <span class="d-md-none ml-3"
            ><span
              v-for="(tag, tagIndex) in $h.renderTags(item.Tags)"
              :key="'tag-' + tagIndex"
              class="f-text-gray mr-1"
              >#{{ $h.formatName(tag, "Name") }}</span
            ></span
          >
        </h3>

        <h4 class="d-none d-md-block">
          <span
            v-for="(tag, tagIndex) in $h.renderTags(item.Tags)"
            :key="'tag-' + tagIndex"
            class="f-text-gray mr-1"
            >#{{ $h.formatName(tag, "Name") }}</span
          >
        </h4>

        <h2 class="d-none d-md-block mb-3">
          {{ $h.formatServiceName(item) }}
        </h2>

        <h4 class="mb-5 d-none d-md-block" style="font-weight: normal" aria-label="hl">
          {{ $h.formatName(item, "Highlight", true) }}
        </h4>

        <el-button
          type="info"
          @click="viewService(item)"
          class="f-btn-common"
          :class="{
            'f-btn-orange': index % 3 == 0,
            'f-btn-blue': index % 3 == 1,
            'f-btn-green': index % 3 == 2,
          }"
        >
          <b>{{ $t.translate("BTN_VIEW_MORE") }}</b
          ><i class="ml-1 el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div>

    <div class="f-bg-blue row justify-content-center">
      <div
        @click="loadServices(true)"
        class="d-flex align-items-center justify-content-center py-3 c-pointer"
        :class="{ 'disabled-more': !Pagination.HaveMore }"
      >
        <h4 class="text-white mb-0">{{ $t.translate("BTN_MORE") }}</h4>
        <i class="ml-2 text-white el-icon-arrow-down"></i>
      </div>
    </div>

    <template v-if="PartnerData">
      <div class="text-center mt-5">
        <h1 class="section-title f-text-orange">{{ PartnerData.pagetitle }}</h1>
      </div>

      <div class="col-md-12 px-3 px-md-5 mb-4">
        <Carousel
          ref="partnerSlide"
          :itemsToShow="$h.isSmall() ? 1 : 3"
          :itemsToScroll="$h.isSmall() ? 1 : 3"
        >
          <template #slides>
            <Slide v-for="(item, index) in Partners" :key="index">
              <div style="display: flex; flex-direction: column; padding: 20px">
                <div class="mb-5" style="text-align: center">
                  <div
                    style="
                      width: 100%;
                      max-width: 350px;
                      padding-top: 50%;
                      background-position: center center;
                      background-size: contain;
                      background-repeat: no-repeat;
                      display: inline-block;
                    "
                    :style="{ 'background-image': `url(${item.Logo})` }"
                  ></div>
                </div>
                <p style="text-align: left" class="mb-3">
                  {{ $h.cutText($h.formatName(item, "Description"), 350) }}
                </p>
              </div>
            </Slide>
          </template>

          <template #addons="{ slidesCount }">
            <Navigation v-if="slidesCount / 3 > 1" />
          </template>
        </Carousel>
      </div>
    </template>

    <!--<div class="row px-3 px-md-6">
      <div v-for="item in Partners" :key="item.PartnerKey" class="col-md-4 px-4 px-md-5">
        <img :src="item.Logo" style="width: 100%" alt="" />
        <p class="mb-5">{{ $h.formatName(item, "Description") }}</p>
      </div>
    </div>-->

    <div class="row" v-if="SecBanners?.length">
      <el-carousel
        ref="secBannerRef"
        :autoplay="false"
        arrow="always"
        trigger="click"
        class="below-banner-cont"
      >
        <el-carousel-item v-for="item in SecBanners" :key="item.ImageKey">
          <img :src="item.Uri" class="below-banner" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>

  <el-backtop class="back-top-mark">
    <img src="/img/front/to_top.png" class="w-100" alt="" />
  </el-backtop>

  <modal v-model:show="showTC" class="consent-modal" :showClose="false">
    <template #header>
      <h2 id="cookiesModalLabel" class="modal-title">
        {{ $t.translate("TTL_UPDATED_TC_PRIVACY") }}
      </h2>
    </template>
    <div class="disc-content mt--1">
      <Consent :key="`currentlang-${$t.getLanguage()}`" />
    </div>
    <template #footer>
      <el-button type="info" class="f-btn-blue" @click="dismissModal(false)">{{
        $t.translate("BTN_DONT_ACCEPT_AND_LOGOUT")
      }}</el-button>

      <el-button type="primary" @click="dismissModal(true)">{{
        $t.translate("BTN_AGREEN_AND_CONTINUE")
      }}</el-button>
    </template>
  </modal>

  <el-dialog
    v-model="showDisabled"
    :title="$t.translate('TTL_CONFIRM_ACTION')"
    custom-class="cus-cont"
    :before-close="dismissShowModal"
  >
    <p>
      {{ $t.translate("MSG_AUTO_LOGOUT_DIDNT_ACCEPT_TC") }}
    </p>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dismissShowModal(null)">{{
          $t.translate("BTN_CONFIRM")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import Consent from "@/components/base/Consent";
import { onMounted, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse, formulateCMSUrl } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";
import { useMq } from "vue3-mq";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { store } from "@/store";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    Consent,
  },
  setup() {
    const route = useRoute();
    const mq = useMq();
    const partnerSlide = ref(null);
    let showTC = ref(false);
    let showDisabled = ref(false);

    let formData = reactive({
      Keyword: null,
      ServiceCatgCode: "",
    });

    let Pagination = reactive({
      Items: [],
      PageSize: 7,
      CurrentPage: 1,
      HaveMore: false,
    });

    let OptionList = ref([
      { value: "", label: "LBL_ALL" },
      { value: "ASSESSMENT", label: "FP_ASSESSMENT" },
      { value: "VERIFICATION", label: "FP_VERIFICATION" },
    ]);

    let Banners = ref([]);
    let Partners = ref([]);
    let SecBanners = ref([]);
    let bannerRef = ref(null);
    let partnerRef = ref(null);
    let secBannerRef = ref(null);

    const searchNow = async () => {
      if (!formData.Keyword) return;
      let query = {
        s: formData.Keyword,
      };
      if (formData.ServiceCatgCode) {
        query.type = formData.ServiceCatgCode;
      }
      router.changeRoute("services", query);
    };

    let loadServices = async (inc) => {
      if (inc) {
        if (!Pagination.HaveMore) return;
        Pagination.CurrentPage += 1;
      }

      let res = await get("/public/service", {
        page: Pagination.CurrentPage,
        size: Pagination.PageSize,

        SortBy: "LastCreated",
        SortOrder: "descending",
      });

      Pagination.Items = [...Pagination.Items, ...res?.data];
      Pagination.HaveMore = res?.totalData > Pagination.Items.length;
    };

    let HomeData = ref({});
    let PartnerData = ref({});

    let loadData = async () => {
      let home = await get("/cms/get/home.json", {}, true);
      HomeData.value = home;

      let banners = await get("/cms/get/banner.json", {}, false);
      Banners.value = _.map(banners?.List, (r) => {
        return {
          BannerContent: r.BannerContent,
          ImageKey: r.id,
          Uri: formulateCMSUrl(r.content),
        };
      });
      if (bannerRef?.value) {
        setTimeout(() => bannerRef.value.next(), 100);
      }

      let partner = await get("/cms/get/partner.json", {}, true);
      PartnerData.value = partner?.PageData;
      Partners.value = _.map(partner?.List, (r) => {
        return {
          Description: r.description,
          PartnerKey: r.id,
          Logo: formulateCMSUrl(r.content),
        };
      });

      setTimeout(() => {
        partnerSlide.value.updateSlideWidth();
      }, 500);

      if (partnerRef?.value) {
        setTimeout(() => partnerRef.value.next(), 100);
      }

      let footerSlider = await get("/cms/get/footer-slider.json", {}, true);
      SecBanners.value = _.map(footerSlider.List, (r) => {
        return {
          Uri: formulateCMSUrl(r.content),
          ImageKey: r.id,
        };
      });
      if (secBannerRef?.value) {
        setTimeout(() => secBannerRef.value.next(), 100);
      }
    };

    onMounted(async () => {
      await loadServices();
      await loadData();

      let { IsTCUpdated, IsShowAutoLogoutMessage } = store.getters["user/getData"];
      showTC.value = IsTCUpdated ? true : false;
      showDisabled.value = IsShowAutoLogoutMessage ? true : false;
    });

    const PartnerChunks = computed(() => {
      return _.chunk(Partners.value || [], 3);
    });

    const viewService = async (row) => {
      router.changeRoute("/service/view/" + row.AssessmentServiceKey);
    };

    const dismissModal = async (IsAccepted) => {
      if (!IsAccepted) {
        await store.dispatch("user/resetData");
        await store.dispatch("user/setData", { IsShowAutoLogoutMessage: true });
        router.reload();
      } else {
        let {
          TermsVersion,
          TermsVersionDate,
          PrivacyVersion,
          PrivacyVersionDate,
        } = await helpers.getTCVersion();
        let res = await post("/user/update/terms", {
          TermsVersion,
          TermsVersionDate,
          PrivacyVersion,
          PrivacyVersionDate,
        });
        if (isValidResponse(res)) {
          await store.dispatch("user/setData", { IsTCUpdated: false });
          showTC.value = false;
        }
      }
    };

    const dismissShowModal = (done) => {
      if (done) done();
      showDisabled.value = false; // hide modal
      store.dispatch("user/setData", { IsShowAutoLogoutMessage: false });
    };

    return {
      dismissShowModal,
      dismissModal,
      showTC,
      showDisabled,
      partnerSlide,
      HomeData,
      PartnerData,
      searchNow,
      mq,
      formData,
      loadServices,
      OptionList,
      Banners,
      Pagination,
      Partners,
      bannerRef,
      partnerRef,
      secBannerRef,
      SecBanners,
      PartnerChunks,
      viewService,
    };
  },
};
</script>
<style>
.banner-img-cont {
  width: 100%;
  height: 50vw;
}
.f-home-content {
  position: absolute;
  width: 60%;
  height: 50vw;
  display: flex;
  align-items: center;
}

.text-white * {
  color: #fff;
}
.f-home-content .welcome-text,
.f-home-content h1 {
  font-size: var(--f-size-welcome-text);
  line-height: var(--l-height-welcome-text);
}
.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-img-cont .el-carousel__container,
.below-banner-cont .el-carousel__container {
  height: 100%;
}
.el-carousel__arrow {
  border: 1.5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-carousel__arrow--left {
  left: 23px;
}
.el-carousel__arrow--right {
  right: 23px;
}
.el-carousel__arrow i {
  font-weight: bold;
  font-size: var(--f-size-carousel-row-i);
}
.el-carousel__indicators--horizontal {
  left: unset;
  right: 6%;
}
.el-carousel__indicator--horizontal {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 25px;
}
.el-carousel__indicator--horizontal .el-carousel__button {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  opacity: 1;
}
.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  background-color: #1673ff;
}

.below-banner-cont {
  width: 100%;
  height: 10vw;
}
.below-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.partner-cont {
  height: unset;
  width: 100%;
  min-height: var(--min-h-partner-item);
}
.partner-cont .el-carousel__container {
  height: 100%;
}
.partner-cont .el-carousel__arrow {
  border: 1.5px solid #5d5d5d;
  background-color: #fff;
}
.partner-cont .el-carousel__arrow i {
  color: #5d5d5d;
}

@media (max-width: 767px) {
  .banner-img-cont {
    width: 100%;
    height: 100vw;
  }
  .f-home-content {
    width: 98%;
    height: 100vw;
  }
  .f-home-content .welcome-text {
    font-size: var(--f-size-welcome-text);
    line-height: var(--l-height-welcome-text);
  }
  .el-carousel__arrow--left {
    left: 10px;
  }
  .el-carousel__arrow--right {
    right: 10px;
  }
  .el-carousel__arrow {
    width: 24px;
    height: 24px;
  }
  .el-carousel__arrow i {
    font-size: var(--f-size-carousel-row-i);
  }
}
.disabled-more {
  cursor: default;
  opacity: 0;
}

.carousel__prev {
  border: 1.5px solid #555555;
  left: -37px;
  border-radius: 100%;
}

.carousel__next {
  border: 1.5px solid #555555;
  right: -37px;
  border-radius: 100%;
}
</style>
